import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const liminaire = () => (
  <Layout>
    <SEO title="Dernières oeillades - Liminaire" />
    <h3 className='underline-title'>Liminaire</h3>
    <br />
    <br />
    <div className="table-responsive">
      <p>
        Nzapa Gbia<br />
        Dieu - Unique<br />
        Nzambe Mokonji<br />
        Nzakomba<br />
        Mungu<br />
        Elohim des montagnes<br />
        Le Tout-Puissant des Hébreux<br />
        Protège-nous contre les pauvres d'imagination<br />
        Pardonne qui déformerait nos intentions<br />
      </p>
    </div>
    <br />
    <Link to="/dernieres-oeillades-table-des-matieres">Table des matières</Link>
  </Layout>
)

export default liminaire
